import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchResultsComponent from "../Components/SearchRes";
import Footer from "../Components/InquiryMineFooter";
import logo4Image from "../img/inquirymine-high-resolution-logo-transparent.png";

interface Ad {
  thumbnail_url: string;
  call_to_action: string;
  target_url: string;
  title: string;
  description: string;
  clickout_url: string;
}

interface Data {
  subId: string;
  ads: Ad[];
}

export const MainResults: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";

  const [results, setResults] = useState<Data>({
    subId: "test",
    ads: [],
  });
  const resetSearchResults = () => {
    setResults({
      subId: "test",
      ads: [],
    });
  };
  const [query, setQuery] = useState(queryParam);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      resetSearchResults();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    const dfetchData = async () => {
      const keyword = encodeURIComponent(query);
      const integrationId = 2986;
      // const userAgent =
      //   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36';
     const requestUrl = `https://ad-server.linkvertise.com/ads?keyword=${keyword}&integration_id=${integrationId}`;
      // const requestUrl = '';
      console.log("----------------------------------------------HED"+results.subId);
      if(results.subId !== "test")return
      try {
      
          const response = await fetch(requestUrl, {
            method: 'POST'
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
  
          const data = await response.json();
          setResults(data);
       
      
      } catch (error) {
        console.error('Error fetching data:', error);
        // You may want to handle errors here, e.g., setResults to null or display an error message
      }
    };

    // Fetch data only if query is not empty
    if (query) {
      dfetchData();
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const keyword = encodeURIComponent(query);
      const integrationId = 2986;
      // const userAgent =
      //   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36';
      const requestUrl = `https://ad-server.linkvertise.com/ads?keyword=${keyword}&integration_id=${integrationId}`;
      // const requestUrl = ' ';
      console.log('------------------------------------------------------KKK'+results.subId);
      if(results.subId !== "test")return
      try {
        const response = await fetch(requestUrl, {
          method: 'POST'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // You may want to handle errors here, e.g., setResults to null or display an error message
      }
    };

    // Fetch data only if query is not empty
    if (query) {
      fetchData();
    }
  });
  // useEffect(() => {
  //   const url = `https://ad-server.linkvertise.com/ads?keyword=${query}&integration_id=2986`;
  //   const data: Data = {
  //     subId: "test",
  //     ads: [
  //       {
  //         thumbnail_url: "https://picsum.photos/id/214/200/300",
  //         call_to_action: "Explore now",
  //         target_url: "https://www.easyrumors.com/ss/p",
  //         title: "More about gaming laptop",
  //         description: "Explore more about gaming laptop.",
  //         clickout_url:
  //           "https://click.linkvertise.com//index.html?hYnIrF..",
  //       },
  //       {
  //         thumbnail_url: "https://picsum.photos/id/215/200/300",
  //         call_to_action: "Shop now",
  //         target_url: "https://www.easyrumors.com/ss/p",
  //         title: "Latest smartphones",
  //         description: "Explore the latest smartphones in the market.",
  //         clickout_url:
  //           "https://click.linkvertise.com//index.html?hYnIrF..",
  //       },
  //     ],
  //   };
  //   setResults(data);
  // }, []);

  return (
    <>
      <div
        className="header"
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <a href="/">
          <img src={logo4Image} alt="inq Search" className="mlogo" style={{ width: "27rem" }} />
        </a>

        <div className="div-io">
          <input
            type="text"
            placeholder="Enter your search here"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSearch} style={{ cursor: "pointer" }}>
            {/* prettier-ignore */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          </button>
        </div>
      </div>
      <SearchResultsComponent
        results={results}
        setRelatedValues={(querys: any) => {
          setQuery(querys);
        }}
      />
      <Footer />
    </>
  );
};

export default MainResults;