import React from "react";
import logo4Image from "../img/inquirymine-high-resolution-logo-transparent.png";
import "./SearchRes.css";

interface Ad {
  thumbnail_url: string;
  call_to_action: string;
  target_url: string;
  title: string;
  description: string;
  clickout_url: string;
}

interface SearchResultsComponentProps {
  results: {
    subId: string;
    ads: Ad[];
  };
  setRelatedValues: any;
}
const handleAdClick = (clickout_url: string) => {
  window.open(clickout_url, "_blank");
};
const SearchResultsComponent: React.FC<SearchResultsComponentProps> = ({
  results,
  setRelatedValues,
}) => {
  return (
    <div className="div-mob">
      {results.ads.length > 0 ? (
        results.ads.map((ad, index) => (
          <div key={index} className="template-container">
            <div>
              <h4>
                <a href={ad.clickout_url} target="_blank"  rel="noreferrer"  title={ad.title}>
                  {ad.title}
                </a>
              </h4>
              <p id="ad-description">{ad.description}</p>
              <small>
                <i>
                  <a href={ad.clickout_url} target="_blank"  rel="noreferrer"  title={ad.title}>
                    {ad.call_to_action}
                  </a>
                </i>
              </small>
            </div>

          </div>
        ))
      ) : (
        <img
          alt="logos"
          src={logo4Image}
          style={{
            maxWidth: "80%",
            maxHeight: "70vh",
            padding: "5rem 0rem 5rem 5rem",
          }}
        />
      )}
       <div className="ad-images">
        <br /><br /><br />
        <div className="scrollable-row">
          {results.ads.map((ad, index) => (
            <div key={index} className="ad-image-container">
              <img
                src={ad.thumbnail_url}
                alt={ad.title}
                title={ad.title}
                onClick={() => handleAdClick(ad.clickout_url)}
              />
              <div className="ad-title">{ad.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchResultsComponent;