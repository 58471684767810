import React from "react";
import "./InquiryMineFooter.css";
import logo4Image from "../img/5597.jpg";
import logo3Image from "../img/inquirymine-favicon-white.png";
const Footer: React.FC = () => {
  return (
    <footer>
       <a href="/">
       <img src={logo3Image} alt="InquiryMine"  style={{height:"6.2rem",}}/>
       <img src={logo4Image} alt="InquiryMine"  style={{height:"6.2rem",}}/>
        </a>
      {/* <div className="horizontal-scroll2"><div className="horizontal-scroll"></div></div> */}
      <br /> <br />
      <div className="footer-InquiryMine-links">
        <a href="https://villagespringgroup.co/" target="_blank" rel="noreferrer" >About</a>
        <a href="https://villagespringgroup.co/contact-us" target="_blank" rel="noreferrer" >Contact</a>
        <small><a href="/privacy-policy" >Privacy Policy</a></small>
        <small><a href="/terms-of-use">Terms Of Use</a></small>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h6 className="InquiryMine-h6" >Village Springs group ltd. ©</h6>
      </div>
    </footer>
  );
};

export default Footer;
