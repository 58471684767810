import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { Home } from "./Pages/Home";
import { MainResults } from "./Pages/MainResults";
import PagePrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/results" element={<MainResults />} />
      <Route path="/Privacy-Policy" element={<PagePrivacyPolicy />} />
      <Route path="/Terms-Of-Use" element={<TermsOfUse />} />
    </Routes>
  );
};

export default App;
