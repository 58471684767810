import { FC } from "react";
import Footer from "../Components/InquiryMineFooter";

import InquiryMineLogo from "../img/inquirymine-high-resolution-logo-transparent.png";

<Footer />;

const PagePrivacyPolicy: FC = () => {
  return (
    <>
      <div className="page">
        <div className="header">
          <div className="container topkal" >
            <a href="/">
              <img className="pp-InquiryMine-img" src={InquiryMineLogo} alt="InquiryMine" style={{width:"17rem"}} />
            </a>
          </div>
        </div>
        <div className="translations-content-container">
          <div className="container" style={{ padding: '5rem' }}>
            <div
              className="tab-content translations-content-item en visible"
              id="en"
            >
              <br />
              <br />

              <br />

              <br />
              <br />
              <ul>
                <li>
                  <br />
                  <h3>Village Springs group ltd.</h3>
                </li>
                <li>
                  <p>Email:  contact@villagespringgroup.co</p>
                </li>
                <li>
                  <p>Phone Number: +972545906686</p>
                </li>
                <li>
                  <p>Adress: Jerusalem Boulevard 78 ,6809017</p>
                </li>
                <li>
                  <small>
                    Visit our website:{" "}
                    <a
                      href="https://www.villagespringgroup.co/"
                      target="_blank" rel="noreferrer"
                    >
                      - www.villagespringgroup.co -
                    </a>
                  </small>
                </li>
              </ul>
              <h2>
                <strong>Village Springs group Privacy Policy.</strong>
              </h2>




              <p>This Privacy Policy describes how Village Springs group (the "Site", "we", "us", or "our") collects, uses, and discloses your personal information when you
                visit, use our services, or make a purchase from inquirymine.org (the "Site") or otherwise communicate with us (collectively, the "Services").
                For purposes of this Privacy Policy, "you" and "your" means you as the user of the Services, whether you are a customer, website visitor, or
                another individual whose information we have collected pursuant to this Privacy Policy.</p>
              <h4>Please read this Privacy Policy carefully.</h4>
              <p>
                By using and accessing any of the Services, you agree to the collection, use, and disclosure of your
                information as described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not use or access any of the Services.
                Changes to This Privacy Policy
                We may update this Privacy Policy from time to time, including to reflect changes to our practices or for other operational, legal, or regulatory
                reasons. We will post the revised Privacy Policy on the Site, update the "Last updated" date and take any other steps required by applicable
                law.</p>
              <h1>Privacy Policy for InquiryMinesearch</h1>

              <p>At InquiryMinesearch, accessible from http://inquirymine.org/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by InquiryMinesearch and how we use it.</p>

              <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

              <h2>Log Files</h2>

              <p>InquiryMinesearch follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics.
                The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages,
                and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site,
                tracking users' movement on the website, and gathering demographic information.
              </p>

              <h2>Cookies and Web Beacons</h2>

              <p>Like any other website, InquiryMinesearch uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>




              <h2>Our Advertising Partners</h2>

              <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

              <ul>
                <li>

                </li>
              </ul>

              <h2>Privacy Policies</h2>

              <p>You may consult this list to find the Privacy Policy for each of the advertising partners of InquiryMinesearch.</p>

              <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on InquiryMinesearch, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

              <p>Note that InquiryMinesearch has no access to or control over these cookies that are used by third-party advertisers.</p>

              <h2>Third Party Privacy Policies</h2>

              <p>InquiryMinesearch's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

              <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>

              <h2>Children's Information</h2>

              <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

              <p>InquiryMinesearch does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

              <h2>Online Privacy Policy Only</h2>

              <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in InquiryMinesearch. This policy is not applicable to any information collected offline or via channels other than this website.</p>

              <h2>Consent</h2>

              <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
              <h3>How We Collect and Use Your Personal Information</h3>
              <p>
                To provide the Services, we collect and have collected over the past 12 months personal information about you from a variety of sources, as
                set out below. The information that we collect and use varies depending on how you interact with us.
                In addition to the specific uses set out below, we may use information we collect about you to communicate with you, provide the Services,
                comply with any applicable legal obligations, enforce any applicable terms of service, and to protect or defend the Services, our rights, and
                the rights of our users or others.</p>

              <h4>What Personal Information We Collect</h4>
              <p>The types of personal information we obtain about you depends on how you interact with our Site and use our Services. When we use the
                term "personal information", we are referring to information that identifies, relates to, describes or can be associated with you. The following
                sections describe the categories and specific types of personal information we collect.
                Information We Collect Directly from You
                Information that you directly submit to us through our Services may include:
                - Basic contact details including your name, address, phone number, email.
                - Order information including your name, billing address, shipping address, payment confirmation, email address, phone number.
                - Account information including your username, password, security questions.
                - Shopping information including the items you view, put in your cart or add to your wishlist.
                - Customer support information including the information you choose to include in communications with us, for example, when sending a
                message through the Services.
                Some features of the Services may require you to directly provide us with certain information about yourself. You may elect not to provide thisinformation, but doing so may prevent you from using or accessing these features.
                Information We Collect through Cookies
                We also automatically collect certain information about your interaction with the Services ("Usage Data"). To do this, we may use cookies,
                pixels and similar technologies ("Cookies"). Usage Data may include information about how you access and use our Site and your account,
                including device information, browser information, information about your network connection, your IP address and other information
                regarding your interaction with the Services.
                Information We Obtain from Third Parties
                Finally, we may obtain information about you from third parties, including from vendors and service providers who may collect information on
                our behalf, such as:
                - Companies who support our Site and Services
                - Our payment processors, who collect payment information (e.g., bank account, credit or debit card information, billing address) to process
                your payment in order to fulfill your orders and provide you with products or services you have requested, in order to perform our contract
                with you.
                - When you visit our Site, open or click on emails we send you, or interact with our Services or advertisements, we, or third parties we work
                with, may automatically collect certain information using online tracking technologies such as pixels, web beacons, software developer kits,
                third-party libraries, and cookies.
                Any information we obtain from third parties will be treated in accordance with this Privacy Policy. We are not responsible or liable for the
                accuracy of the information provided to us by third parties and are not responsible for any third party's policies or practices. For more
                information, see the section below, Third Party Websites and Links.</p>
              <h3>How We Use Your Personal Information</h3>
              <p>- Providing Products and Services. We use your personal information to provide you with the Services in order to perform our contract with
                you, including to process your payments, fulfill your orders, to send notifications to you related to you account, purchases, returns, exchangesor other transactions, to create, maintain and otherwise manage your account, to arrange for shipping, facilitate any returns and exchanges
                and to enable you to post reviews.
                - Marketing and Advertising. We use your personal information for marketing and promotional purposes, such as to send marketing,
                advertising and promotional communications by email, text message or postal mail, and to show you advertisements for products or services.
                This may include using your personal information to better tailor the Services and advertising on our Site and other websites.
                - Security and Fraud Prevention. We use your personal information to detect, investigate or take action regarding possible fraudulent, illegal
                or malicious activity. If you choose to use the Services and register an account, you are responsible for keeping your account credentials safe. We highly recommend that you do not share your username, password, or other access details with anyone else. If you believe your account
                has been compromised, please contact us immediately.
                - Communicating with you. We use your personal information to provide you with customer support and improve our Services. This is in our
                legitimate interests in order to be responsive to you, to provide effective services to you, and to maintain our business relationship with you.
                Cookies
                We may also permit third parties and services providers to use Cookies on our Site to better
                tailor the services, products and advertising on our Site and other websites.
                Most browsers automatically accept Cookies by default, but you can choose to set your browser to remove or reject Cookies through your
                browser controls. Please keep in mind that removing or blocking Cookies can negatively impact your user experience and may cause some of
                the Services, including certain features and general functionality, to work incorrectly or no longer be available. Additionally, blocking Cookies
                may not completely prevent how we share information with third parties such as our advertising partners.</p>


              <h4>Third Party Websites and Links</h4>
              <p>Our Site may provide links to websites or other online platforms operated by third parties. If you follow links to sites not affiliated or controlledby us, you should review their privacy and security policies and other terms and conditions. We do not guarantee and are not responsible for
                the privacy or security of such sites, including the accuracy, completeness, or reliability of information found on these sites. Information you
                provide on public or semi-public venues, including information you share on third-party social networking platforms may also be viewable by
                other users of the Services and/or users of those third-party platforms without limitation as to its use by us or by a third party. Our inclusion ofsuch links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators, except as disclosed on
                the Services.
                Children's Data
                The Services are not intended to be used by children, and we do not knowingly collect any personal information about children. If you are the
                parent or guardian of a child who has provided us with their personal information, you may contact us using the contact details set out below
                to request that it be deleted.
                As of the Effective Date of this Privacy Policy, we do not have actual knowledge that we "share" or "sell" (as those terms are defined in
                applicable law) personal information of individuals under 16 years of age.</p>

              <h4>Security and Retention of Your Information</h4>
              <p>Please be aware that no security measures are perfect or impenetrable, and we cannot guarantee "perfect security." In addition, any
                information you send to us may not be secure while in transit. We recommend that you do not use unsecure channels to communicate
                sensitive or confidential information to us.
                How long we retain your personal information depends on different factors, such as whether we need the information to maintain your
                account, to provide the Services, comply with legal obligations, resolve disputes or enforce other applicable contracts and policies.
                Your Rights and Choices
                Depending on where you live, you may have some or all of the rights listed below in relation to your personal information. However, these
                rights are not absolute, may apply only in certain circumstances and, in certain cases, we may decline your request as permitted by law.
                - Right to Access / Know. You may have a right to request access to personal information that we hold about you, including details relating to
                the ways in which we use and share your information.
                - Right to Delete. You may have a right to request that we delete personal information we maintain about you.
                - Right to Correct. You may have a right to request that we correct inaccurate personal information we maintain about you.
                - Right of Portability. You may have a right to receive a copy of the personal information we hold about you and to request that we transfer it
                to a third party, in certain circumstances and with certain exceptions.</p>



              <p>- Right to Limit and/or Opt out of Use and Disclosure of Sensitive Personal Information. You may have a right to direct us to limit our use
                and/or disclosure of sensitive personal information to only what is necessary to perform the Services or provide the goods reasonably
                expected by an average individual.
                - Restriction of Processing: You may have the right to ask us to stop or restrict our processing of personal information.
                - Withdrawal of Consent: Where we rely on consent to process your personal information, you may have the right to withdraw this consent.
                - Appeal: You may have a right to appeal our decision if we decline to process your request. You can do so by replying directly to our denial.
                - Managing Communication Preferences: We may send you promotional emails, and you may opt out of receiving these at any time by using
                the unsubscribe option displayed in our emails to you. If you opt out, we may still send you non-promotional emails, such as those about your
                account or orders that you have made.
                You may exercise any of these rights where indicated on our Site or by contacting us using the contact details provided below.
                We will not discriminate against you for exercising any of these rights. We may need to collect information from you to verify your identity,
                such as your email address or account information, before providing a substantive response to the request. In accordance with applicable
                laws, You may designate an authorized agent to make requests on your behalf to exercise your rights. Before accepting such a request from
                an agent, we will require that the agent provide proof you have authorized them to act on your behalf, and we may need you to verify your
                identity directly with us. We will respond to your request in a timely manner as required under applicable law.</p>

            </div>
          </div>
        </div>

        <div className="footer"></div>
      </div>
      <Footer />
    </>
  );
};
export default PagePrivacyPolicy;
